"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WSWrapper = exports.WSServerWrapper = void 0;
class WSWrapper {
    webSocket;
    onMessageCallback;
    constructor(webSocket, onMessageCallback = null) {
        this.webSocket = webSocket;
        this.onMessageCallback = onMessageCallback;
        if (!this.webSocket.on)
            return;
        this.webSocket.on('message', (data) => this.handleDto(data));
    }
    on(event, fnc) {
        if (!this.webSocket.on)
            return;
        this.webSocket.on(event, fnc);
    }
    onMessage(callback) {
        this.onMessageCallback = callback;
    }
    handleDto(data) {
        const message = JSON.parse(data.toString());
        if (this.onMessageCallback) {
            this.onMessageCallback(message);
        }
    }
    send(data) {
        if (this.webSocket.send) {
            this.webSocket.send(JSON.stringify(data));
        }
    }
}
exports.WSWrapper = WSWrapper;
class WSServerWrapper extends WSWrapper {
    constructor(ws) {
        super(ws);
    }
    on(event, fnc) {
        if (!this.webSocket.on)
            return;
        if (event === 'connection') {
            return this.webSocket.on('connection', (ws) => {
                const wrappedWs = new WSWrapper(ws);
                return fnc(wrappedWs);
            });
        }
        super.on(event, fnc);
    }
}
exports.WSServerWrapper = WSServerWrapper;
