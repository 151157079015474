import {
  CursorDto,
  ImageDto,
  MouseDto,
  SocketDataDto,
  StartDto,
  WSWrapper,
} from '@playwright-screenshare/libs';
import { REACT_APP_HYPERVISOR_URI } from '../env';
import React, { MouseEvent, useCallback, useEffect, useState } from 'react';

declare let WebSocket: {
  prototype: WebSocket;
  new (
    uri: string,
    protocols?: string | string[] | null,
    options?: {
      headers: { [headerName: string]: string };
      [optionName: string]: any;
    } | null
  ): WebSocket;
  readonly CLOSED: number;
  readonly CLOSING: number;
  readonly CONNECTING: number;
  readonly OPEN: number;
};

const Home = () => {
  const [connected, setConnected] = useState(false);
  const [image, setImage] = useState('');
  const [webSocket, setWebSocket] = useState<WSWrapper<WebSocket>>();
  const [cursor, setCursor] = useState('');

  useEffect(() => {
    if (connected) {
      return;
    }

    const hypervisorSocket = new WebSocket(REACT_APP_HYPERVISOR_URI);
    hypervisorSocket.addEventListener('error', function (event) {
      console.log('WebSocket error: ', event);
    });
    const wsWrapperHypervisor = new WSWrapper(hypervisorSocket);
    hypervisorSocket.onopen = () => {
      wsWrapperHypervisor.send({
        type: 'signal',
        data: {
          start: (window.location.hash || 'airbnb').replace('#', ''),
        },
      });
    };

    hypervisorSocket.onmessage = (hypervisorMessageEvent) => {
      const hypervisorMessage: StartDto = JSON.parse(
        hypervisorMessageEvent.data
      );

      if (
        !hypervisorMessage.type ||
        hypervisorMessage.type !== 'start' ||
        !hypervisorMessage.data
      ) {
        return hypervisorSocket.close();
      }

      hypervisorSocket.close();

      console.log('NEW WEBSOCKET', hypervisorMessage.data);
      const webSocket = new WebSocket(
        hypervisorMessage.data.websocketUri,
        null,
        {
          headers: {
            ['fly-force-instance-id']: hypervisorMessage.data.machineId,
          },
        }
      );
      const wsWrapper = new WSWrapper(webSocket);

      setWebSocket(wsWrapper);

      const recordKeyBoard = () =>
        document.addEventListener('keydown', (event: KeyboardEvent) => {
          wsWrapper.send({
            type: 'keyboard',
            data: {
              key: event.key,
            },
          });
        });

      webSocket.onopen = () => {
        console.log('Open');
        recordKeyBoard();
      };

      webSocket.onmessage = (event) => {
        const message: SocketDataDto = JSON.parse(event.data);
        console.log('MEssage');

        if (message.type === 'image') {
          const image = (message as ImageDto).data;

          setImage('data:image/jpeg;base64,' + image.img);
        } else if (message.type === 'cursor') {
          const cursor = (message as CursorDto).data;

          setCursor(cursor.cursor);
        }
      };
    };

    setConnected(true);
  }, [connected, setConnected, webSocket, setWebSocket, setCursor]);

  const mouseEvent = (
    event: MouseEvent,
    type: 'mouse_click' | 'mouse_move'
  ) => {
    if (!webSocket) {
      return;
    }

    const position = event.currentTarget.getBoundingClientRect();
    console.log(JSON.stringify(position, null, 4));

    const mouseDto: MouseDto = {
      type,
      data: {
        x: event.pageX - position.x,
        y: event.pageY - position.y,
      },
    };

    webSocket.send(mouseDto);
  };

  const mouseMove = useCallback(
    (event: MouseEvent) => mouseEvent(event, 'mouse_move'),
    [webSocket]
  );

  const mouseClick = useCallback(
    (event: MouseEvent) => mouseEvent(event, 'mouse_click'),
    [webSocket]
  );

  const mouseScroll = useCallback(
    (event: React.UIEvent<HTMLElement>) => {
      if (!webSocket) {
        return;
      }

      const position = event.currentTarget.scrollTop;
      webSocket.send({
        type: 'scroll',
        data: {
          position,
        },
      });
    },
    [webSocket]
  );

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          cursor,
          height: 600,
          maxHeight: 600,
          overflow: 'auto',
          border: '5px solid black',
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'relative',
          }}
          onScroll={mouseScroll}
        >
          {image && (
            <img
              src={image}
              onMouseMove={mouseMove}
              onClick={mouseClick}
              alt=""
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
